import axios from 'axios';
import jwt_decode from "jwt-decode";

let jwtToken;

export async function refreshToken() {
	if (jwtToken == null)
	{
		const token = await axios.get('/api/token');
		jwtToken = token.data;
		setTimeout(() => {jwtToken = null}, 300000); // refresh every 5 mins token
	}
}

export function getToken() {
	if (jwtToken == null)
	{
		refreshToken();
	}
	return jwtToken;
}

export async function getUsername() {
	await refreshToken();
	var decoded = jwt_decode(jwtToken);
	return decoded.preferred_username;
}	

export async function getRoles() {
	await refreshToken();
	var decoded = jwt_decode(jwtToken);
	return decoded.resource_access["isys-horus"] == null ? [] : decoded.resource_access["isys-horus"].roles;
}	