import React, { Component } from 'react';

import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import styled from '@emotion/styled'

import {getUsername} from '../../token/Token.js';

import {FiLogOut, FiMenu, FiHome} from 'react-icons/fi';
import {BiCalendar} from 'react-icons/bi';
import {IoTicketOutline} from 'react-icons/io5'
import logo from "../logoGael.png";

import "./Banner.css"

export default class App extends Component {	
	constructor(props) {
		super(props);
		this.state = {
			mainMenu: null,
			userMenu: null,
			username: null,
			gateway: null,
			keycloakLogout: null
		}
	}
		
	componentDidMount() {
		getUsername().then((name) => {
			axios.get('/api/gateway').then((gateway) => {
				axios.get('/api/keycloakLogout').then((keycloakLogout) => {
					this.setState({username: name, gateway: gateway.data, keycloakLogout: keycloakLogout.data})
				});
			});	
		});
	}
 	
 	handleMainMenuClick = (event) => {
    	this.setState ({mainMenu: event.currentTarget});
  	};
		
	handleMainMenuClose = () => {
	    this.setState ({mainMenu: null});
	};
 	
 	handleUserMenuClick = (event) => {
    	this.setState ({userMenu: event.currentTarget});
  	};
		
	handleUserMenuClose = () => {
	    this.setState ({userMenu: null});
	};

	render() {	
		const {mainMenu, userMenu, username, gateway, keycloakLogout} = this.state;
		
		const Banner = styled.div({
			height: "50px",
			'background-color': "white",
			width: "calc(100% - 20px)",
			'text-align': "center",
			padding: "10px",
			margin: "0px 10px 20px 10px"
		})
		
		const Left = styled.div({
			margin: "10px",
			position: "absolute",
			top: "5px",
			left: "0px",
			color: "#002453",
		})
		
		const Right = styled.div({
			margin: "10px",
			position: "absolute",
			top: "5px",
			right: "0px",
			color: "#002453",
		})
		
	    const coloredIcon = {
			color: "white"
		}
				
		return (
			<Banner>
				<img src={logo} height="50px" alt="GAEL logo"/>
				<Left>
          			<IconButton className="bannerMenu" onClick={this.handleMainMenuClick} size="small" sx={{ ml: 2, 'margin-left': '0px !important' }}>
           				<Avatar sx={{ width: 32, height: 32, 'background-color':'#002453' }}>
           					<FiMenu style={coloredIcon}/>
       					</Avatar>						
          			</IconButton>
					
					<Menu autoFocus={false} style={{zIndex: "5000"}} open={mainMenu != null} onClose={this.handleMainMenuClose} anchorReference="anchorPosition"
				        anchorPosition={mainMenu !== null ? { top: mainMenu.getBoundingClientRect().bottom, left: mainMenu.getBoundingClientRect().left } : undefined}>
				        <MenuItem onClick={() => {window.location.href = gateway.horus+"lo	gin"}}>
				          <ListItemIcon>
				            <FiHome style={coloredIcon} />
				          </ListItemIcon>
				          <ListItemText>HORUS - Home Of Running Useful Services</ListItemText>
				        </MenuItem>
				        <MenuItem onClick={() => {window.location.href = gateway.maat}}>
				          <ListItemIcon>
				            <BiCalendar style={coloredIcon} />
				          </ListItemIcon>
				          <ListItemText>MAAT - Milestones And Activities Timetable</ListItemText>
				        </MenuItem>
				        <MenuItem onClick={() => {window.location.href = gateway.thot}}>
				          <ListItemIcon>
				            <IoTicketOutline style={coloredIcon} />
				          </ListItemIcon>
				          <ListItemText>THOT - Ticket Handling Overall Tool</ListItemText>
				        </MenuItem>
			        </Menu>
				</Left>	
				<Right>
          			<IconButton className="bannerMenu" onClick={this.handleUserMenuClick} size="small" sx={{ ml: 2 }}>
           				<Avatar sx={{ width: 32, height: 32, 'background-color':'#002453' }}>
           					{username == null ? "?" : username.charAt(0).toUpperCase()}
       					</Avatar>
          			</IconButton>
          			
					<Menu autoFocus={false} style={{zIndex: "5000"}} open={userMenu != null} onClose={this.handleUserMenuClose} anchorReference="anchorPosition"
				        anchorPosition={userMenu !== null ? { top: userMenu.getBoundingClientRect().bottom, left: userMenu.getBoundingClientRect().left } : undefined}>
				        <MenuItem onClick={() => {window.location.href = keycloakLogout}}>
				          <ListItemIcon>
				            <FiLogOut style={coloredIcon} />
				          </ListItemIcon>
				          <ListItemText>Logout</ListItemText>
				        </MenuItem>
			        </Menu>
				</Right>
			</Banner>
		);
		
	}
}