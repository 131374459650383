import React from 'react';
import ReactDOM from 'react-dom';

import App from './lib/App.js';

import 'bootstrap/dist/css/bootstrap.css';

ReactDOM.render(
	<App />,
	document.getElementById('app'),
);
