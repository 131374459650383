import React, { Component } from 'react';
	
import Banner from './banner/Banner.js';
import Footer from './footer/Footer.js';
import Home from './home/Home.js';
import styled from '@emotion/styled'

import {getRoles} from '../token/Token.js';

export default class App extends Component {	
	constructor(props) {
		super(props);
								
		this.state = {
			isConnected: null
		}
	}
		
	componentDidMount() {
		getRoles().then((roles) => {
			if (roles.length > 0) {
				this.setState({isConnected: true})
			}
			else
			{				
				this.setState({isConnected: false})
			}
		}).catch(() => {
			this.setState({isConnected: false})
		});
	}

	render() { 
		const {isConnected} = this.state
		
		const Viewport = styled.div({
  			'height': "calc(100vh - 90px)",
  			'overflow-y': "scroll",
			'border-top': "1px solid #002453"
		})
				
		if (isConnected) {document.title = 'CDS - HORUS';}
				
		return (
			<>
				{ isConnected == null ? <></> : 
					!isConnected ? 
					<>
						<Home />
						<Footer />
					</>   :
					<>
						<Banner />
						<Viewport>
							{this.props.children}
						</Viewport>
						<Footer />
				    </>
		    	}
		    </>
		);
		
	}
}