import React, { Component } from 'react';
	
import styled from '@emotion/styled'

export default class App extends Component {	
	render() {	
		const Banner = styled.div({
			position: "absolute",
			bottom: "0px",
			color: "#002453",
			'background-color': "white",
			'text-align': "center",
			'border-top': "1px solid #002453",
			width: "100%",
			'padding-bottom': "2.5px",
			'font-size': "11px"
		})
		
		return (
			<Banner>
				Coordination, Delivery and Support department
			</Banner>
		);
		
	}
}