import React, { Component } from 'react';
	
import styled from '@emotion/styled'

import logo from "../logoGael.png";

export default class App extends Component {	
	render() { 		
		const Viewport = styled.div({
			'text-align': "center",
  			'margin-top': "150px"		
		})
		
		const Title = styled.h1({
			color: "#002453",
		})
		
		const Enter = styled.h3({
			color: "#002453",
			cursor: "pointer"
		})
		
		return (
			<Viewport>  
				<img src={logo} alt="GAEL logo"/>
				<Title>Coordination, Delivery and Support Information system</Title>
				<Enter onClick={() => {window.location.href='/login'}}>- Enter -</Enter>
			</Viewport>
		);
		
	}
}