import React, { Component } from 'react';
	
import Horus from './horus/Horus.js'
import styled from '@emotion/styled'

export default class App extends Component {
	render() { 		
		const Main = styled.div({
			'padding-left': "5px",
			'text-align': "center",
			'padding-top': "10px"
		})
		
		return (
			<React.StrictMode>
					<Horus>	
						<Main>Welcome to the Coordination, Delivery and Support Home Of Running Useful Services<br />
						Please select a service in the top left menu.</Main>					
					</Horus>
			</React.StrictMode>
		);
		
	}
}